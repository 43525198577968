<div
  [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <app-explanation-banner></app-explanation-banner>

  <div
    [ngClass]="{
      'container-explanation-steps-large': isLarge(),
      'container-explanation-steps-medium': isMedium(),
      'container-explanation-steps-small': isSmall(),
      'container-explanation-steps-xsmall': isXSmall()
    }"
  >
    <div
    [ngClass]="{
      'container-explanation-step-large': isLarge(),
      'container-explanation-step-medium': isMedium(),
      'container-explanation-step-small': isSmall(),
      'container-explanation-step-xsmall': isXSmall()
    }"
    
      *ngFor="let explanationStep of getExplanationSteps()"
    >
      <app-explanation-step
        [explanationStep]="explanationStep"
      ></app-explanation-step>
    </div>
  </div>
</div>