<footer>
  <mat-divider></mat-divider>
  <div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }" >
      <span><a href="/"><img class="planetvg-banner" src="/assets/img/planetvg-banner.png"  alt="planetvg-banner"/></a></span>
      <span class="mat-headline-6 container-slogan">The vegan loyalty experience</span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Planet VG Apps</span>
      <span><a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty" target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png" alt="Planet VG App - Google Play Store"></a></span>
      <span><a href="https://apps.apple.com/app/planet-vg/id6505109649" target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/apple-app-store-badge.png" alt="Planet VG App - Apple App Store"></a></span>

    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Grow your business sustainably?</span>
      <span><a href="{{ partnerPortalUrl }}" mat-button>Become a Partner</a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Support</span>
      <span><a href="#" mat-button routerLink="/faq">Frequenty Asked Questions</a></span>
      <span><a href="#" mat-button routerLink="/accessibility">Accessibility</a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Legal</span>
      <span><a href="" mat-button routerLink="/user-terms">Users Terms & Conditions</a></span>
      <span><a href="" mat-button routerLink="/privacy-policy">Privacy Policy</a></span>
    </div>
    <div [ngClass]="{
      'container-column-large': isLarge(),
      'container-column-medium': isMedium(),
      'container-column-small': isSmall(),
      'container-column-xsmall': isXSmall()
    }">
      <span class="mat-headline-6">Company</span>
      <span><a href="#" mat-button routerLink="/about">About</a></span>
      <span><a href="#" mat-button routerLink="/climate-pledge">Climate Pledge</a></span>
      <span><a href="#" mat-button routerLink="/shout-outs">Shout Outs</a></span>
      <span><a href="#" mat-button routerLink="/contact">Contact Us</a></span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div [ngClass]="{
    'container-trademarks-large': isLarge(),
    'container-trademarks-medium': isMedium(),
    'container-trademarks-small': isSmall(),
    'container-trademarks-xsmall': isXSmall()
  }" >
    <span>©️ {{ currentYear }} <a href="https://almostlevel5.com">Almost Level 5 B.V.</a> All rights reserved. Planet VG is a registered trademark of Almost Level 5 B.V., registered in the Netherlands and other countries.</span>
  </div>
</footer>
