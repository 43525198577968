<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          {{ title }}
        </mat-card-title>
        <mat-card-subtitle>
          {{ description }}
        </mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content> </mat-card-content>
  </mat-card>
</div>
