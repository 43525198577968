import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'planetvg-storefront';

  constructor(private readonly router: Router,) {
    // Do nothing
  }

  ngOnInit() {
    this.router.events
      .pipe()
      .subscribe(() => {
        // HACK: This is because we are using the mat-sidenav-content from Angular Material.
        // This overrides the anchorScrolling: 'enabled' and scrollPositionRestoration : 'top' settings.
        // So we need to do this manually.
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0);
      }
    );
  }
}
