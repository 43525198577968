<div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <h1>Planet VG</h1>
    <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />
    <div class="container-card">
        <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
            <mat-card-header>
                <mat-card-title>Contact Us</mat-card-title>
                <mat-card-subtitle>All Day Every Day</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="container-column">
                    <p>
                        <br/>
                        If you'd like to learn more about Planet VG, its features, or just want to say hello, feel free to reach out to us.
                        <br/>
                        <br/>
                        Almost Level 5 B.V. <br />
                        Jan Pieterz. Coenstraat 7<br />
                        2595WP The Hague<br />
                        The Netherlands<br />
                        <a href="mailto:info@planet.vg">info&#64;planet.vg</a><br />
                        <br/>
                        Chamber of Commerce: 93949138<br/>
                    </p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<app-bottom-menu></app-bottom-menu>