<div [ngClass]="{
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div class="container-banner">
    <h1>Help Center</h1>
    <div class="banner-content">
      <h2>
        We hope you'll find your answer here. If not, then feel free to
        <a href="/contact">contact</a> the Planet VG Chefs.
      </h2>
    </div>
  </div>
</div>
<div>
  <app-bottom-menu></app-bottom-menu>
</div>