<div
[ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}"
>
  <div
  [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }"
    *ngFor="let feature of features"
  >
    <app-feature-grid-tile [title]="feature.title" [description]="feature.description"></app-feature-grid-tile>
  </div>
</div>
