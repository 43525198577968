import { Component } from '@angular/core';

@Component({
  selector: 'app-resources-menu',
  templateUrl: './resources-menu.component.html',
  styleUrls: ['./resources-menu.component.scss']
})
export class ResourcesMenuComponent {

}
