<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Build Connections</mat-card-title>
        <mat-icon>diversity_1</mat-icon>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div class="container-card-content">
        <h2 class="item-title">
          Reward your customers for supporting you in your endeavor to create a
          better world.
        </h2>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div class="container-card-footer">
        <img
          src="/assets/img/banners/large/product-banner-prepared-build-connections-l.webp"
          alt="prepared-build-conections"
        />
      </div>
    </mat-card-footer>
  </mat-card>
</div>
