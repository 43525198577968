<div class="container">
  <div class="container-content">
    <div class="container-content-banner">
      <div class="container-menu">
        <h2>What's on our menu?</h2>

        <h3>
          Planet VG empowers you to create stronger and lasting connections with the <span
            class="primary-title">plant-based</span>
          companies you want to support, whether they are new or existing.
        </h3>
        <h3>
          Easily find vouchers, stamp cards and redeem
          rewards using our <span class="primary-title">climate-</span>, <span class="primary-title">animal-</span> and
          <span class="primary-title">user-friendly</span> loyalty app.
        </h3>
      </div>
    </div>
  </div>
</div>