import { Action, createReducer, on } from "@ngrx/store";
import {
    clearSessionAction,
} from "./app.actions";
import { AppState } from "./app.state";

export const APP_REDUCER_KEY = "appReducerKey";

export const initialAppState: AppState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null
};

export const appReducer = createReducer(
    initialAppState,
    on(
        clearSessionAction, (state: AppState) => ({
            ...state,
            loading: false,
            loaded: false,
            success: false,
            errorMessage: null,
        })
    ),
);

export function appReducerExport(state: AppState | undefined, action: Action) {
    return appReducer(state, action);
}