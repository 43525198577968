<div class="container">
  <mat-card
    appearance="outlined"
    class="column-card mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <div mat-card-avatar class="lunch-avatar"></div>
      <mat-card-title>Loyalty Campaigns</mat-card-title>
      <mat-card-subtitle
        >Serving Lunch : 12h - 16h</mat-card-subtitle
      >
    </mat-card-header>
    <div *ngIf="isXLarge(); else largeContentImage">
      <img
        src="/assets/img/banners/xlarge/product-banner-lunch-xl.webp"
        alt="breakfast-bring-in-new-customers" 
      />
    </div>
    <mat-card-content>
      <h2 class="item-title">Be A Local, Support Your Local</h2>
      <h3 class="item-subtitle">
        Become a member of your favorite club. Join their loyalty campaign. Build stronger connections and support your club with their climate and animal rights ambitions.
      </h3>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #largeContentImage>
  <div *ngIf="isLarge(); else mediumContentImage">
    <img
      src="/assets/img/banners/large/product-banner-lunch-l.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #mediumContentImage>
  <div *ngIf="isMedium(); else smallContentImage">
    <img
      src="/assets/img/banners/medium/product-banner-lunch-m.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #smallContentImage>
  <div *ngIf="isSmall(); else xSmallContentImage">
    <img
      src="/assets/img/banners/small/product-banner-lunch-s.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img
      src="/assets/img/banners/xsmall/product-banner-lunch-xs.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>