<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>Level Up</mat-card-title>
            <mat-icon>sports_esports</mat-icon>
        </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div class="container-card-content" >
        <h2 class="item-title">
          Enhance your sales with promotions and stamp cards.
        </h2>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div [ngClass]="{
        'card-footer-large': isLarge(),
        'card-footer-medium': isMedium(),
        'card-footer-small': isSmall(),
        'card-footer-xsmall': isXSmall()
      }">
      <img src="/assets/img/banners/large/product-banner-prepared-level-up-l.webp" alt="prepared-level-up">
      </div>
    </mat-card-footer>
  </mat-card>
</div>
