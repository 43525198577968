import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, filter } from 'rxjs';
import { clearSessionAction } from 'src/app/stores/global/app.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public partnerPortalUrl : string = environment.partnerPortalUrl;

  ngUnsubscribeState = new Subject<void>();
  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
  hasBusinessAccount: boolean = false;
  accountPortalUrl: string = environment.accountPortalUrl;
  hasNoActiveSubscriptionPlanOrder : boolean;

  private _isXLarge : boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;

  constructor(
    private readonly router: Router, 
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly store: Store<any>, 
    private readonly responsive: BreakpointObserver) { }

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$.pipe(filter(isAuthenticated => Boolean(isAuthenticated))).subscribe(
      _ => {
        // Do nothing
      });

      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
        result => {
          const breakpoints = result.breakpoints;
  
          this._isXLarge = false;
          this._isLarge = false;
          this._isMedium = false;
          this._isSmall = false;
          this._isXSmall = false;
  
          if (breakpoints[Breakpoints.Medium]) {
            this._isMedium = true;
            console.log("Medium");
          }
          else if (breakpoints[Breakpoints.Small]) {
            this._isSmall = true;
            console.log("Small");
          }
          else if (breakpoints[Breakpoints.XSmall]) {
            this._isXSmall = true;
            console.log("X Small");
          }
          else if (breakpoints[Breakpoints.Large]) {
            this._isLarge = true;
            console.log("Large");
          }
          else {
            this._isXLarge = true;
            console.log("X Large");
          }
        }
      );
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.store.dispatch(clearSessionAction());
    this.oidcSecurityService.logoff().subscribe(_ => { 
      this.oidcSecurityService.logoffLocal();
      this.router.navigate(['/home']);
    });
  }

  public goToCustomerPortal() : void {
    this.router.navigate(['/billing/portal']);
  }

  isXLarge(): boolean {
    return this._isXLarge;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }

  scrollTop(event) {
    window.scroll(0,0);
  }
}
