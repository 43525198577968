<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>Learn</mat-card-title>
        <mat-icon>school</mat-icon>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div class="container-card-content">
        <h2 class="item-title">
          Understand how your sales perform. Discover your customers' needs to serve them better.
        </h2>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div [ngClass]="{
        'card-footer-large': isLarge(),
        'card-footer-medium': isMedium(),
        'card-footer-small': isSmall(),
        'card-footer-xsmall': isXSmall()
      }">
      <img src="/assets/img/banners/large/product-banner-prepared-kyc-l.webp" alt="prepared-learn">
      </div>
    </mat-card-footer>
  </mat-card>
</div>
