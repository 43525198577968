import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './presentation/pages/home/home.component';
import { NavigationComponent } from './core/components/navigation/navigation.component';
import { OidcCallbackComponent } from './presentation/pages/security/oidc-callback/oidc-callback.component';
import { AboutComponent } from './presentation/pages/about/about.component';
import { FaqPageComponent } from './presentation/pages/faq-page/faq-page.component';
import { PrivacyPolicyPageComponent } from './presentation/pages/privacy-policy/privacy-policy-page.component';
import { UserTermsPageComponent } from './presentation/pages/user-terms/user-terms-page.component';
import { ClimatePledgeComponent } from './presentation/pages/climate-pledge/climate-pledge.component';
import { ContactComponent } from './presentation/pages/contact/contact.component';
import { ShoutOutsPageComponent } from './presentation/pages/shout-outs-page/shout-outs-page.component';
import { PlanetBPageComponent } from './presentation/pages/planet-b-page/planet-b-page.component';
import { AccessibilityComponent } from './presentation/pages/accessibility/accessibility.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { UserTermsComponent } from './core/components/user-terms/user-terms.component';
import { FaqComponent } from './core/components/faq/faq.component';

const routes: Routes = [
  {
    path: '', component: NavigationComponent, children: [
      // Public 
      { path: 'oidc-callback', component: OidcCallbackComponent },
      { path: 'about', component: AboutComponent },
      { path: 'privacy-policy', component: PrivacyPolicyPageComponent },
      { path: 'user-terms', component: UserTermsPageComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'faq', component: FaqPageComponent },
      { path: 'climate-pledge', component: ClimatePledgeComponent },
      { path: 'shout-outs', component: ShoutOutsPageComponent },
      { path: 'accessibility', component: AccessibilityComponent },
      { path: 'home', component: HomeComponent },
      { path: '', redirectTo: "/home", pathMatch: "full" },
    ]
  },

  // App Pages
  { path: 'app/privacy-policy', component: PrivacyPolicyComponent },
  { path: 'app/user-terms', component: UserTermsComponent },
  { path: 'app/faq', component: FaqComponent },

  // Unauthorized pages
  { path: 'forbidden', component: PlanetBPageComponent },
  { path: 'unauthorized', component: PlanetBPageComponent },
  { path: '**', component: PlanetBPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
