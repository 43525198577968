import { Component } from '@angular/core';

@Component({
  selector: 'app-user-terms-page',
  templateUrl: './user-terms-page.component.html',
  styleUrls: ['./user-terms-page.component.scss']
})
export class UserTermsPageComponent {

}
