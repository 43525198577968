<div class="container">
  <mat-card
    appearance="outlined"
    class="column-card mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <div mat-card-avatar class="dinner-avatar"></div>
      <mat-card-title>Receive Vouchers</mat-card-title>
      <mat-card-subtitle
        >Serving Dinner : 16h - 22h </mat-card-subtitle
      >
    </mat-card-header>
    <div *ngIf="isXLarge(); else largeContentImage">
      <img
        src="/assets/img/banners/xlarge/product-banner-lets-start-saving-xl.webp"
        alt="breakfast-bring-in-new-customers" 
      />
    </div>
    <mat-card-content>
      <h2 class="item-title">Get Rewarded</h2>
      <h3 class="item-subtitle">
        Get rewarded for your support. Together we can save the planet and make this world a better place for animals and people.
      </h3>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #largeContentImage>
  <div *ngIf="isLarge(); else mediumContentImage">
    <img
      src="/assets/img/banners/large/product-banner-lets-start-saving-l.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #mediumContentImage>
  <div *ngIf="isMedium(); else smallContentImage">
    <img
      src="/assets/img/banners/medium/product-banner-lets-start-saving-m.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #smallContentImage>
  <div *ngIf="isSmall(); else xSmallContentImage">
    <img
      src="/assets/img/banners/small/product-banner-lets-start-saving-s.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img
      src="/assets/img/banners/xsmall/product-banner-lets-start-saving-xs.webp"
      alt="breakfast-bring-in-new-customers" 
    />
  </div>
</ng-template>