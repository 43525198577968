<div
  [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <h1>Shout Out to the people from Behance</h1>
  <div
    [ngClass]="{
      'container-shout-out-large': isLarge(),
      'container-shout-out-medium': isMedium(),
      'container-shout-out-small': isSmall(),
      'container-shout-out-xsmall': isXSmall()
    }"
  >
    <mat-list>
      <mat-list-item>Sergey Ryadovoy</mat-list-item>
      <mat-list-item>Ivan Gladkikh</mat-list-item>
    </mat-list>
  </div>
  
  <h1>Shout Out to the people from Unsplash</h1>
  <div
    [ngClass]="{
      'container-shout-out-large': isLarge(),
      'container-shout-out-medium': isMedium(),
      'container-shout-out-small': isSmall(),
      'container-shout-out-xsmall': isXSmall()
    }"
  >
    <mat-list>
      <mat-list-item>Alex Haney</mat-list-item>
      <mat-list-item>Ambitious Studio - Rick Barrett</mat-list-item>
      <mat-list-item>Annie Spratt</mat-list-item>
      <mat-list-item>Brooke Cagle</mat-list-item>
      <mat-list-item>Charles Deluvio</mat-list-item>
      <mat-list-item>Claudio Schwarz</mat-list-item>
      <mat-list-item>Deryn Macey</mat-list-item>
      <mat-list-item>Jorge Zapata</mat-list-item>
      <mat-list-item>Kayleigh Harrington</mat-list-item>
      <mat-list-item>Like Meat*</mat-list-item>
      <mat-list-item>Marlis Trio Akbar</mat-list-item>
      <mat-list-item>Megumi Nachev</mat-list-item>
      <mat-list-item>Roam in Color</mat-list-item>
    </mat-list>
  </div>
  <div class="container-disclaimer">
    <p>
      <small
        >* we are not affiliated with or sponsored by Like Meat.<br />we just
        have a weak spot for vegan burgers.</small
      >
    </p>
  </div>
  <img src="/assets/img/planetvg_icon.png" width="48px" alt="planetvg-logo" />
</div>
