import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-grid-tile',
  templateUrl: './feature-grid-tile.component.html',
  styleUrls: ['./feature-grid-tile.component.scss']
})
export class FeatureGridTileComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  description: string;

  constructor() {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }
}
