<div class="container">
    <h1>Privacy Policy</h1>
    <h2><strong>Last Updated:</strong> July 31st, 2024</h2>
  
    <p>
      This privacy policy has been compiled to better serve those who are
      concerned with how their personal data is being used by Almost Level 5.
      Please read our privacy policy carefully to get a clear understanding of how
      we collect, use, protect or otherwise handle your personal data in
      accordance with the Services, Mobile Application and/or Website as defined
      in <a href="/partner-terms">Almost Level 5’s Terms and Conditions</a>.
    </p>
  
    <h1>General Privacy Policy</h1>
    <h2>1. Who is responsible for your personal data?</h2>
    <p>
      The Dutch company, Almost Level 5 B.V.(“Almost Level 5“, “Planet VG”, “we”,
      “us”, “our”), is the controller of the personal data you submit to us and
      responsible for your personal data under the Dutch law
      <em>Algemene verordening gegevensbescherming</em> of 28 May 2018 on the
      protection of persons with regard to the processing of personal data, as
      amended, which implements the EU General Data Protection Regulation
      (Regulation EU: 2016/679). You can find more information here on how Almost
      Level 5 protects your privacy, including information on how to exercise your
      rights regarding your data.
    </p>
    <h2>2. Where is your personal data stored?</h2>
    <p>
      The data that we collect are stored within the European Economic Area
      (“EEA”) but may also be transferred to and processed in a country outside of
      the EEA. Any such transfer of your personal data will be carried out in
      compliance with applicable laws. We store your data for as long as you have
      an active account with Almost Level 5. We will delete all your personal data
      if you request to delete your account.
    </p>
    <h2>3. What types of personal data do we collect?</h2>
    <p>
      We collect personal data that you submit to us. Below you can see the 3
      areas that we collect your personal data:
    </p>
    <div class="container-area">
      <ul>
        <li>1. Account creation:</li>
        <ul>
          <li>
            1.1 When appropriate, you may be asked to enter your name, email
            address, mailing address, phone number, profile picture, birthday,
            veganniversary date, or other details such as your purchase
            preferences to help you with your Planet VG experience.
          </li>
        </ul>
        <li>2. Planet VG usage data:</li>
        <ul>
          <li>
            2.1 When you receive a stamp or voucher, we collect all information in
            regard to the stamp and voucher reward transactions, such as time and
            date, partner ID, etc.;
          </li>
          <li>
            2.2 When you use Planet VG we automatically receive your location (in
            cases you have authorized us to do so);
          </li>
          <li>
            2.3 When you provide feedback for a business, we collect all your
            ratings and comments.
          </li>
        </ul>
        <li>3. App usage data</li>
        <ul>
          <li>
            3.1 Almost Level 5 may also collect the Application usage details such
            as Application opening date and time and user interactions with the
            Application such as clicks, swipes, etc.
          </li>
        </ul>
      </ul>
    </div>
    <h2>4. How do we use your personal data?</h2>
    <p>We may use your personal data for the following purposes:</p>
    <ul>
      <li>to provide you with the Planet VG Services;</li>
      <li>to improve Planet VG in order to better serve you;</li>
      <li>
        to personalize users’ experience and to offer you the most relevant
        rewards, memberships and promotions;
      </li>
      <li>
        to allow partners to send you newsletters, marketing messages and to
        notify partners (that you have added to your memberships) when you are
        present at their physical location;
      </li>
      <li>
        to communicate with you for Services-related or research purposes
        including via emails, notifications, text messages, or other messages,
        which you agree to receive;
      </li>
      <li>
        to communicate with you for marketing and promotional purposes via emails,
        notifications, or other messages, consistent with any permissions you may
        have communicated to us;
      </li>
      <li>
        to enable and promote the Services and other services or products, either
        within or outside the Services, including features and content of the
        Services and products and services made available through the Services;
      </li>
      <li>
        to allow us to better serve you in responding to your customer service
        requests;
      </li>
      <li>to send you gift-cards, if needed/required;</li>
      <li>
        to enforce this Privacy Policy, the Terms and Conditions, and any other
        terms that you have agreed to, including to protect the rights, property,
        or safety of Almost Level 5, its users, or any other person;
      </li>
      <li>
        to provide you with features, information, advertising, or other content
        which is influenced by your location and your location in relation to
        other Planet VG users; and
      </li>
      <li>as otherwise stated in this Privacy Policy.</li>
    </ul>
    <p>
      We will keep your data for as long as necessary to fulfil the purposes above
      or for as long as we are required by law. After this your personal data will
      be deleted.
    </p>
    <h2>5. What are your rights?</h2>
    <p>
      You have the right to request information about the personal data we hold on
      to, at any time (free of charge, at reasonable intervals). If your data is
      incorrect, incomplete or irrelevant, you can ask to have the information
      corrected or removed. We cannot remove your data when there is a legal
      storage requirement, such as bookkeeping rules or when there are other
      legitimate grounds to keep the data. You can withdraw your consent to us
      using the data for marketing purposes at any time. You can contact us by
      sending an email to
      <a href="mailto:privacy@almostlevel5.com">privacy&#64;almostlevel5.com</a>.
    </p>
  
    <h2>6. Who has access to your personal data?</h2>
    <p>
      Your personal data may be shared with partners that you have included in
      your memberships. The below mentioned information may be disclosed to any
      third party:
    </p>
    <ul>
      <li>
        aggregated and anonymized statistical information that can’t be used to
        identify, contact, or locate a single person, or to identify an individual
        in context;
      </li>
    </ul>
    <p>
      As described above, Almost Level 5 might disclose some personal data to
      partners or other third parties that are disclosed below. Almost Level 5 is
      not responsible for the content or privacy practices/policies of partners or
      other third parties. Before disclosing your personal information to partners
      or any other third parties, we advise you to examine their terms and
      conditions of use.
    </p>
    <h2>7. Third party disclosure</h2>
    <p>
      We do not sell, trade, or otherwise transfer your personal data to outside
      parties. This does not include partners, website hosting partners and other
      parties who assist us in operating our Website, Application, conducting our
      business, or serving our users, so long as those parties agree to keep this
      information confidential. We may also release information when its release
      is appropriate to comply with the law, enforce our site policies, or protect
      ours or others’ rights, property, or safety.
    </p>
    <p>
      We do share some of your data (e.g. phone model/brand/O.S. version, device
      id, user id, etc.) with some third parties that are required for running and
      maintaining our apps. These partners are:
    </p>
    <ul>
      <li>
        <a href="https://firebase.google.com/terms/data-processing-terms"
          >Google LLC</a
        >
        using Firebase Cloud Messaging to send push notification to a specific
        device using the device id.
      </li>
      <li>
        <a href="https://legal.hubspot.com/dpa">HubSpot</a> for customer services
        and marketing.
      </li>
      <li>
        <a href="https://stripe.com/en-nl/legal/dpa"
          >Stripe Payments Europe, Limited</a
        >
        for storing all necessary payment data to process successful payments.
      </li>
      <li>
        <a href="https://www-uploads.scaleway.com/DPA_EN_v17072024_0ca3d55b58.pdf"
          >Scaleway SAS</a
        >
        for primary data storage and processing.
      </li>
      <li>
        <a href="https://helpcenter.netcup.com/en/wiki/general/dpa"
          >NetCup GmbH</a
        >
        for primary data storage and processing.
      </li>
      <li>
        <a href="https://mailtrap.io/dpa/">Railsware Products Studio LLC</a> using
        MailTrap for sending emails.
      </li>
    </ul>
    <h2>8. Cookies</h2>
    <p>
      A cookie is a small text file that is saved to, and, during subsequent
      visits, retrieved from your computer or mobile device. Almost Level 5 uses
      cookies to enhance and simplify your visit. We do not use cookies to store
      personal information, or to disclose information to third parties.
    </p>
    <p>There are two types of cookies: first-party and third-party cookies.</p>
    <p>
      First-party cookies are cookies that are set by Almost Level 5 when you use
      our Websites. Third-party cookies are cookies that are set by parties or
      organizations other than Almost Level 5. Some Almost Level 5 or Planet VG
      web pages may also contain content from or link to other sites which may set
      their own cookies.
    </p>
    <p>
      You can easily erase cookies from your computer or mobile device using your
      browser. You can choose to disable cookies, or to receive a notification
      each time a new cookie is sent to your computer or mobile device. Please
      note that if you choose to disable cookies, you will not be able to take
      advantage of all our features.
    </p>
    <p><strong>First-party cookies</strong></p>
  
    <table>
      <tr>
        <th>Name</th>
        <th>Provider</th>
        <th>Purpose</th>
        <th>Duration</th>
      </tr>
      <tr>
        <td>auth_session_id_legacy</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>Session</td>
      </tr>
      <tr>
        <td>auth_session_id</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>Session</td>
      </tr>
      <tr>
        <td>kc_restart</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>Session</td>
      </tr>
      <tr>
        <td>keycloak_identity_legacy</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>Session</td>
      </tr>
      <tr>
        <td>keycloak_identity</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>Session</td>
      </tr>
      <tr>
        <td>keycloak_session_legacy</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>2 weeks</td>
      </tr>
      <tr>
        <td>keycloak_session</td>
        <td>Almost Level 5</td>
        <td>Authentication</td>
        <td>2 weeks</td>
      </tr>
    </table>
    <p></p>
    <p><strong>Third-party cookies</strong></p>
    <p>We use third-party cookies for processing the payments using Stripe.</p>
    <table>
      <tr>
        <th>Name</th>
        <th>Provider</th>
        <th>Purpose</th>
        <th>Duration</th>
      </tr>
      <tr>
        <td>__stripe_mid</td>
        <td>Stripe Payments Europe, Limited</td>
        <td>Payments</td>
        <td>Day</td>
      </tr>
      <tr>
        <td>__stripe_sid</td>
        <td>Stripe Payments Europe, Limited</td>
        <td>Payments</td>
        <td>Day</td>
      </tr>
      <tr>
        <td>stripe.customerportal.csrf</td>
        <td>Stripe Payments Europe, Limited</td>
        <td>Payments</td>
        <td>Session</td>
      </tr>
    </table>
    <p></p>
  
    <h2>9. Links</h2>
    <p>
      The sites almostlevel5.com, planet.vg and the Planet VG apps may include
      links to other websites which do not fall under our supervision. We cannot
      accept any responsibility for the protection of privacy or the content of
      these websites, but we offer these links to make it easier for our visitors
      to find more information about specific subjects.
    </p>
    <h2>10. Security</h2>
    <p>
      We have taken technical and organizational measures to protect your data
      from loss, manipulation, and unauthorized access. We continually adapt our
      security measures in line with technological progress and developments. Your
      password protects your user account, so you should use a unique and strong
      password, limit access to your computer and browser, and log out after
      having used the Services. Your data is always encrypted during transfer and
      storage. While we take data protection precautions, no security measures are
      completely secure, and we do not guarantee the security of user information.
    </p>
    <h2>11. Almost Level 5 and Children under the age of 13</h2>
    <p>
      Children under the age of 13 or under the applicable age limit (the “Age
      Limit”) are not allowed to use Planet VG Services and we request them to not
      submit any personal information to Almost Level 5. Almost Level 5 does not
      knowingly collect personal information from children under the Age Limit. If
      you are under the Age Limit, do not use the Services and do not provide any
      personal information to us. If you are a parent of a child under the Age
      Limit and become aware that your child has provided personal information to
      Almost Level 5, please contact us at
      <a href="mailto:privacy@almostlevel5.com">privacy&#64;almostlevel5.com</a>
      and you may request exercise of your applicable access, rectification,
      cancellation, and/or objection rights.
    </p>
    <h2>12. Changes to the Privacy Policy</h2>
    <p>
      We reserve the right, at our sole discretion, to modify or replace this
      Privacy Policy at any time. When we make material changes to the Privacy
      Policy, we will provide you with prominent notice as appropriate under the
      circumstances, e.g., by displaying a prominent notice within the Services or
      by sending you an email. In some cases, we will notify you in advance, and
      your continued use of the Services after the changes have been made will
      constitute your acceptance of the changes. Please therefore make sure you
      read any such notice carefully. If you do not wish to continue using the
      Services under the new version of the Privacy Policy, you may terminate your
      use of the Services by sending an email to
      <a href="mailto:privacy@almostlevel5.com">privacy&#64;almostlevel5.com</a>.
    </p>
    <h2>13. Deleting your Account?</h2>
    <p>
      In case you would like to delete your Planet VG account you can take one of
      the following actions:
    </p>
    <ul>
      <li>
        <p>
          Delete your account using the following webpage:
          </p>
          <ul>
            <li>
              Go to the <a href="https://account.planetvg.nl/privacy"
              >https://account.planetvg.nl/privacy</a
            > page.
            </li>
            <li>Log in with your Planet VG account</li>
            <li>Press the <strong>Delete Account</strong> button</li>
          </ul>      
      </li>
      <br/>
      <li>
        <p>
          Delete your account using the Planet VG App:
        </p>
          <ul>
            <li>
              Press the <mat-icon>menu</mat-icon><strong>Menu</strong> button.
            </li>
            <li>
              Go to the <mat-icon>settings</mat-icon><strong>Settings</strong> page.
            </li>
            <li>
              Go to the <mat-icon>privacy_tip</mat-icon><strong>Privacy</strong> page.
            </li>
            <li>
              Press the <strong>Delete Account</strong> button.
            </li>
          </ul> 
      </li>
    </ul>
    <p>
      When we delete your account all your collected stamps, vouchers, your
      memberships and other activities that you have performed will be deleted as
      well.
    </p>
    <p>
      You can only delete your Planet VG account if there is no Planet VG Business
      Account connected to it. In such case, first terminate the Planet VG
      Business Account before deleting your Planet VG Account.
    </p>
    <h2>14. Contacting Us</h2>
    <p>
      If there are any questions regarding this privacy policy, you may contact us
      using the contact details below:
    </p>
    <p>
      Almost Level 5 B.V.<br />
      Jan Pieterz. Coenstraat 7<br />
      2595WP The Hague <br />
      The Netherlands<br />
      <a href="mailto:info@almostlevel5.com">info&#64;almostlevel5.com</a>
    </p>
  </div>
    